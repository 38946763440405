@import "mixins"
.loading
  composes: loading from "../signup.module.sass"
  height: 199px

.register
  composes: register from "../details/layouts/default/default.module.sass"

.registration
  display: flex
  flex-direction: column

  &:has(.errorTxt)
    .activeStep > span
      background: rgb(var(--particularColor2))

  &.sr-step-second
    transition: height 0.4s

  &.sr-step-complete
    transition: height 0.18s

.signInBtn
  composes: signInBtn from "../details/layouts/default/default.module.sass"

.header
  @include flex
  min-height: 39px
  height: 39px
  border-bottom: var(--login_headerBorder)
  font-size: 16px
  color: rgba(var(--secondaryColor))
  @include bold

  > div:not(.back)
    font-size: 12px
    margin-left: auto
    margin-right: 16px
    text-align: right

    > span
      display: block

.registerSteps
  min-height: 42px
  height: 42px
  max-width: 172px
  margin: 10px auto
  position: relative
  background: var(--registerCpf_uiSwitchContainerBg)
  border: 1px solid rgba(0, 0, 0, 0.5)
  border-radius: 30px
  padding: 3px
  @include flex
  color: rgb(var(--secondaryColor))

  > div
    border: 1px solid var(--registerCpf_ButtonIconBg)
    background: var(--registerCpf_ButtonBg)
    width: 34px
    min-width: 34px
    height: 34px
    border-radius: 100%
    @include flex
    justify-content: center

    > span
      background: var(--registerCpf_ButtonIconBg)
      border-radius: 28px
      width: 28px
      min-width: 28px
      height: 28px
      @include flex
      font-family: var(--specialFont)
      font-size: 14px
      justify-content: center

    &.activeStep
      border-color: var(--registerCpf_ButtonActiveIconFill)

      > span
        background: var(--registerCpf_ButtonActiveIconBg)

.separatorLine
  height: 2px
  flex: 1
  min-width: 84px
  margin: 0 6px
  background: var(--registerCpf_ButtonIconBg)
  position: relative

  &:before
    content: ""
    width: 0
    height: 100%
    position: absolute
    left: 0
    background: var(--registerCpf_ButtonActiveIconFill)
    transition: width 0.4s

  &.active:before
    width: 50%

  &.goNext:before
    width: 100%

.usernameIcon
  > span
    @include flex

  svg
    height: 18px

    path
      fill: rgb(var(--secondaryColor))

.stepDone
  border-color: var(--registerCpf_ButtonStepDoneBg) !important

  > span
    background: var(--registerCpf_ButtonStepDoneBg) !important

    > span
      @include flex

      svg
        width: 15px
        height: 12px

        path
          fill: var(--registerCpf_ButtonStepDoneSvgColor)

.text
  margin-top: 12px
  line-height: 16px

.signupForm
  display: flex
  flex-direction: column
  --labelWidth: 68px

  .cpfField
    width: 100%
    margin-bottom: auto
    display: flex

    .field
      flex: 1

      input::placeholder
        text-transform: unset
        opacity: 1
        color: var(--registerCpf_fieldPlaceholder)
        font-size: 10px
        letter-spacing: 1px

    button
      min-width: 60px
      margin-left: 10px

  button
    height: 30px
    text-transform: uppercase

  :global(.required)
    &:before
      content: ""
      position: absolute
      right: 0
      top: 0
      z-index: 1
      height: 16px
      width: 16px
      background-color: rgb(var(--particularColor2))
      border: inherit
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%)
      transform: rotate(180deg)
      border-radius: 0 0 0 2px

    &:after
      content: "*"
      color: rgb(var(--secondaryColor))
      position: absolute
      right: 3px
      top: 2px
      font-size: 11px
      z-index: 2

.checkedRow
  @include flex
  width: 100%

  > span
    @include flex
    @include fadeIn(.7s)
    margin: 0 0 10px 6px

    svg
      width: 14px
      height: 10px

      path
        fill: #5cba60

.errorTxt
  height: auto
  overflow: hidden
  display: flex
  align-items: flex-start
  max-height: 0
  animation: maxHeight .4s
  animation-fill-mode: forwards
  @keyframes maxHeight
    100%
      max-height: 200px
  + button
    margin-top: 10px

  > span
    width: 100%
    border-radius: 3px
    border: 1px dashed rgb(var(--particularColor2))
    background: var(--registerCpf_ErrorTextBg)
    padding: 8px 10px
    line-height: 14px

.birthDate
  width: 100%
  margin-bottom: 10px
  position: relative
  @include flex

  > span:first-of-type
    text-transform: capitalize
    padding-right: 8px
    font-size: 12px
    color: rgb(var(--primaryColor))
    min-width: var(--labelWidth)
    width: var(--labelWidth)
    line-height: 14px

  > div
    > div:first-of-type
      width: 100%
      border: 1px solid transparent
      outline: none
      background: var(--field_bg)
      color: rgb(var(--secondaryColor))
      border-radius: 2px
      padding: 8px 10px
      --common_dropdown_placeholderColor: var(--registerCpf_fieldPlaceholder)

      &:after
        background: rgb(var(--primaryColor))

    > section
      background: var(--customDdl_bg)
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5), -1px 1px 4px rgba(0, 0, 0, 0.5)
      > div:last-of-type > div
        background: var(--customDdl_bg)

      ul > li
        @include flex
        padding: 0 8px
        height: 26px
        border-top: 1px solid var(--customDdl_borderTop)
        border-bottom: 1px solid var(--customDdl_borderBottom)
        color: rgb(var(--secondaryColor)) !important

        &:last-of-type
          border-bottom: 0

        > i
          margin-right: 8px

      & :global
        li:hover:not(.selected-li)
          background: rgba(var(--customDdl_activeBg), 0.5)
          border-top-color: rgba(var(--customDdl_activeBg), 0.8)

        li.selected-li
          background: rgb(var(--customDdl_activeBg))
          border-top-color: rgb(var(--customDdl_activeBg))
          cursor: default

.field
  width: 100%
  margin-bottom: 10px
  transition: margin .16s
  position: relative
  display: flex
  align-items: flex-start

  &:has(:global(.intl-tel-input))
    flex-wrap: wrap

  :global
    .required
      position: relative

    .field-error
      position: relative
      top: 5px
      font-size: 12px
      white-space: normal
      text-overflow: unset

  label,
  :global(.intl-tel-input-label)
    text-transform: capitalize
    padding-right: 8px
    font-size: 12px
    min-width: var(--labelWidth)
    width: var(--labelWidth)
    line-height: 14px
    @include flex
    height: 30px
    @include flex

    + div
      flex: 1

  input,
  .dropdown > div
    width: 100%
    border: 1px solid transparent
    outline: none
    background: var(--field_bg)
    border-radius: 2px
    padding: 7px 10px
    height: 30px
    line-height: 14px

  input:disabled
    background: var(--registerCpf_fieldDisabledBg)!important
    color: rgb(var(--primaryColor), .3)!important

  & :global
    .intl-tel-input
      display: block

      &:has(.phone-widget:disabled)
        .selected-flag
          background: var(--registerCpf_fieldDisabledBg)!important
          .selected-dial-code
            color: rgb(var(--primaryColor), .3)
          .arrow, .iti-flag
            opacity: .4

      + :global(.field-error)
        left: var(--labelWidth)
        flex: 1 100%
        max-width: calc(100% - var(--labelWidth))

      .flag-container
        height: 30px

        &:hover
          .selected-flag
            color: rgb(var(--secondaryColor))
          .arrow
            background-color: rgb(var(--secondaryColor))

      .country-list
        top: 33px
        background-color: var(--login_countryListBg)
        border: 0
        max-height: 160px

        &::-webkit-scrollbar-thumb
          border-color: var(--login_countryListBg)

      .phone-widget

        &::placeholder
          color: var(--registerCpf_fieldPlaceholder) !important

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
    box-shadow: inset 0 0 0 30px var(--field_bg) !important
    border-color: var(--field_bg) !important
    -webkit-text-fill-color: rgba(var(--secondaryColor))

  input::placeholder
    color: var(--registerCpf_fieldPlaceholder)

  .dropdown > div
    padding: 8px 10px 8px 10px
    overflow: hidden

.password
  input
    padding-right: 34px

  :global(.viewPass)
    right: 12px
    top: 10px

.formSubmit
  @include flex
  width: 100%

  button
    flex: 1
    min-width: calc(50% - 6px)
    max-width: unset
    border-radius: 2px
    height: 30px
    font-size: 12px
    text-transform: uppercase

    &:nth-child(2)
      margin-left: 12px

  .closeBtn
    max-width: 150px
    margin: 0 auto

.congratsTxt
  > p
    @include flex
    flex-direction: column
    justify-content: center
    color: rgb(var(--secondaryColor))
    flex: 1
    margin: 10px 0 22px

    strong
      font-size: 18px
      margin-bottom: 12px

  .formSubmit
    padding-bottom: 10px

.checkbox
  @include flex
  margin-bottom: 12px
  margin-top: auto

  > i
    margin-right: 8px
    border-radius: 2px
    box-shadow: none!important
    border-color: rgb(var(--secondaryColor))!important

    &::before
      background-color: var(--registerCpf_fieldDisabledBg)!important

  p
    color: rgb(var(--secondaryColor))
    line-height: 14px

    a
      margin-left: 4px
      text-decoration: underline
      text-underline-offset: 2px

.recoverAccount
  :global(.loader)
    left: 0
    top: 0
    > span
      color: rgba(var(--primaryColor), 0.6)
      font-size: 18px
      width: 18px
      height: 18px

  > div:not(.header)
    padding: 0

    form
      :global(.field-error)
        position: relative
        top: 5px
        font-size: 12px
        white-space: normal
        text-overflow: unset

      > div
        > div
          margin-bottom: 14px
          --login_fieldBg: var(--field_bg)

        &:has(:global(.intl-tel-input))
          > span
            top: -9px

.back
  @include flex
  &:hover
    @include pointer
  > span
    @include flex
    margin-right: 8px

    > span
      @include flex
      transform: rotate(90deg)
      transform-origin: 45%
      margin-top: -2px
