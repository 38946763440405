@import "mixins"
.verificationKycModal :global(.modal)
  border-radius: 8px
  width: 360px
  display: flex
  flex-direction: column
  padding: 0 16px 14px
  background: var(--register_bg)
  border-radius: 8px
  width: 360px
  display: flex
  flex-direction: column
  padding: 0 16px 14px
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
  border-radius: 8px

  :global(.loader)
    position: static
    min-height: 418px
    span
      font-size: 18px
      width: 18px
      height: 18px

.row
  display: flex
  align-items: flex-start

  > div:nth-child(2)
    margin-left: 10px

.field
  composes: field from "../signup/cpf/cpf-signup.module.sass"
  flex: 1

  &:has(.dropdown)
    flex-direction: column

  &:has(.dropdown) > span, > div label
    text-transform: capitalize
    height: auto
    font-size: 12px
    margin-bottom: 6px
    line-height: 14px

  > div
    width: 100%

  .dropdown > div:first-of-type
    width: 100%
    border: 1px solid transparent
    outline: none
    background: var(--field_bg)
    border-radius: 2px
    padding: 7px 10px
    height: 30px
    line-height: 14px
    overflow: hidden
    max-width: 100%

.header
  composes: header from "../signup/cpf/cpf-signup.module.sass"

.subTitle
  font-size: 16px
  @include bold
  color: rgb(var(--secondaryColor))
  @include flex
  margin: 10px 0 12px
  flex-direction: column

  > span
    margin-top: 8px
    font-size: 12px
    font-weight: normal

.verification

  :global(.loader)
    background: transparent

  > form
    padding: 6px 0

.buttonContainer
  display: flex
  button
    height: 30px
    &:nth-child(2)
      flex: 1
      margin-left: 10px

    &:nth-child(1)
      width: 110px

.laterMessage
  min-height: 160px
  padding: 10px
  @include flex
  flex-direction: column
  justify-content: center

  > p
    font-size: 14px
    line-height: 18px
    width: 80%
    max-width: 200px
    text-align: center
    margin-bottom: auto
    margin-top: 30px
    color: rgb(var(--secondaryColor))

  button
    width: 80%
    max-width: 100px
    border-radius: 2px
    text-transform: uppercase
