@import "mixins"
.providers
  display: inline-flex
  transition: all 0.3s
  padding: 7px 6px 0 0
  text-transform: uppercase
  background: var(--casino_providersBg)
  border-radius: 0 0 4px 4px
  font-size: 12px
  margin-right: auto
  margin-left: auto
  overflow: hidden
  flex-wrap: wrap
  justify-content: center
  width: 100%
  border-top: 1px solid var(--casino_providersBtnOpendColor)

  > button
    min-width: 100px
    width: 100px
    height: 34px
    border-radius: 3px
    background: var(--casino_providerBg)
    color: rgb(var(--secondaryColor), 0.7)
    margin: 0 0 6px 6px
    padding: 0 6px
    overflow: hidden
    @include flex
    justify-content: center
    @include bold

    span
      @include flex
      justify-content: center
      svg
        width: 100%
        height: auto

    &:not(:hover):not(.active) svg
      opacity: 0.7

    &:hover:not(.active)
      background: var(--casino_providerHoverBg)
      color: rgb(var(--secondaryColor))

    &.active
      color: var(--casino_providerActiveColor)
      cursor: default !important
      background: var(--casino_providerActiveBg)
      svg
        path
          fill: var(--casino_providerActiveColor)

.gamesCount
  background: var(--casino_providerGamesCountBg)
  color: rgb(var(--secondaryColor))
  position: absolute
  right: 0
  top: 0
  font-size: 9px
  line-height: 10px
  min-width: 15px
  padding: 1px 3px
  border-bottom-left-radius: 3px
  font-style: unset

.container
  min-height: 19px
  overflow: hidden
  display: flex
  border-radius: 4px
  flex-direction: column
  max-width: calc(100% - 76px)
  margin-left: auto

.providerBtn
  background: var(--casino_leftNavBg)
  color: rgb(var(--secondaryColor))
  @include flex
  justify-content: center
  @include bold
  text-transform: uppercase
  transition: all 0.3s
  height: 20px
  border-radius: 4px
  font-size: 12px
  position: relative
  z-index: 1

  :global(.svg-icon)
    margin: 0 10px
    transform: rotate(180deg)
    transition: all 0.2s

    span
      @include flex

      svg
        path
          fill: var(--casino_providersBtnOpendColor)

  &:hover,
  &.opened:hover
    @include pointer
    color: var(--casino_providersBtnOpendColor)

  &.opened
    color: rgb(var(--secondaryColor))
    border-radius: 4px 4px 0 0
    > span
      transform: rotate(0deg)
