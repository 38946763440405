@import "mixins"
.forgotPass
  composes: login from "../form/login-form.module.sass"
  form
    position: relative
    font-size: var(--button_fontSize)

    > p:first-child
      margin: 10px 0

  p
    margin-bottom: 8px
    margin-top: -2px
    padding-right: 8px
    line-height: 14px

.setNewPassTxt
  margin: 12px 0 28px
  display: block

.forgotPassField
  display: flex
  margin-top: 6px
  > div
    margin-right: 2px
    flex: 1

  button
    margin: 0 0 0 4px
    min-width: 90px
    font-size: var(--base_fontSize)
    line-height: var(--base_fontSize)
    height: var(--login_fieldHeight)

.forgotPassCompleted
  min-height: 208px
  padding-top: 20px
  @include flex
  flex-direction: column
  justify-content: center
  p
    margin-bottom: 40px
    font-size: 14px
    line-height: 20px
    text-align: center
  button
    height: var(--field_height)
    min-width: 80px
    background: var(--loginBtn_bg)
    color: var(--loginBtn_color)
    &:hover
      background: var(--loginBtn_hoverBg)
    &:active
      background: var(--loginBtn_activeBg)

.forgotPassHeader
  composes: loginHeader from "../form/login-form.module.sass"

.iconGif
  composes: iconGif from "../form/login-form.module.sass"

.email *
  min-width: 32px

.active
  composes: active from "../form/login-form.module.sass"

.orTxt
  @include bold
  @include flex
  justify-content: center
  margin: -4px 0 2px
  text-transform: uppercase
